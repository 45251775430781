import { LoaderFunctionArgs } from 'react-router-dom';
import { confirmEmail } from '@/api/auth';
import { formError } from '@/api/client';

export default function confirmEmailLoader({ request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams;
  const email = searchParams.get('e');
  const token = searchParams.get('t');

  if (!email || !token) {
    return formError('Invalid confirmation link');
  }

  return confirmEmail(email, token);
}
