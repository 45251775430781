import { Form, FormMethod } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import PageHeader from '@/components/PageHeader';

const StyledForm = styled(Form)`
  flex-grow: 1;
  padding-block: 2rem;
  margin-inline: auto;
  width: 100%;
  max-width: 80rem;
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`;

export interface TwoColumnFlowProps {
  readonly title: string;
  readonly subtitle: string;
  readonly method?: FormMethod;
  readonly leftColumn: React.ReactNode;
  readonly rightColumn: React.ReactNode;
  readonly onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
}

/**
 * This component serves as the basis for both the "Add Location" page and the first
 * step of the "Pay Invoice" page where the user needs to look up their invoice.
 *
 * By default, it uses a react-router Form with the POST method, but this can be
 * overridden using the onSubmit parameter.
 */
export default function TwoColumnForm({
  title,
  subtitle,
  method = 'POST',
  leftColumn,
  rightColumn,
  onSubmit,
}: TwoColumnFlowProps) {
  return (
    <StyledForm method={method} onSubmit={onSubmit}>
      <div className="overflow-hidden">
        <PageHeader
          className="px-3 px-sm-4"
          title={title}
          subtitle={subtitle}
        />

        <Row className="row-gap-4 px-sm-4">
          <StyledCol lg={7}>{leftColumn}</StyledCol>
          <StyledCol lg={5}>{rightColumn}</StyledCol>
        </Row>
      </div>
    </StyledForm>
  );
}
