import { useActionData } from 'react-router-dom';
import { FormResponse } from '@/api/client';
import FormInput from '@/components/FormInput';
import PasswordInput from '@/components/PasswordInput';
import SubmitButton from '@/components/SubmitButton';
import AuthForm from '@/features/auth/AuthForm';

export default function ResetPasswordPage() {
  const actionData = useActionData<FormResponse>();

  return (
    <AuthForm title="Reset password">
      <FormInput
        tag={PasswordInput}
        name="password"
        label="New Password"
        required
        actionData={actionData}
      />
      <SubmitButton color="primary" className="w-100 mt-3">
        Reset Password
      </SubmitButton>
    </AuthForm>
  );
}
