import FindInvoiceForm from '@/features/invoices/FindInvoiceForm';

export default function AddLocationPage() {
  return (
    <FindInvoiceForm
      title="Add Location"
      subtitle="Enter some details from an invoice for the location you'd like to add to your account."
      actionLabel="Add Location"
      cancelRoute="/invoices"
    />
  );
}
