import React from 'react';
import { FaArrowLeft } from 'react-icons/fa6';
import { Form } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import styled from 'styled-components';
import fishLogo from '@/assets/fish-logo.svg';
import FlashMessage from '@/components/FlashMessage';
import { breakpointDown, breakpointUp } from '@/utils/mediaQueries';
import LinkButton from '../../components/LinkButton';
import PageFooter from '../../components/PageFooter';

const PageWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: var(--bs-gray-200);
`;

const CenterWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block: 2rem;
`;

const AuthCard = styled(Card)`
  max-width: 500px;
  width: 100%;

  ${breakpointDown('sm')} {
    border-inline: none;
    border-radius: 0;
    max-width: none;
  }
`;

const AuthCardBody = styled(CardBody)`
  padding: 2rem;
  padding-top: 3rem;

  ${breakpointUp('sm')} {
    padding-inline: 3rem;
  }
`;

const FishLogo = styled.img`
  display: block;
  width: 160px;
  margin-inline: auto;
  margin-bottom: 1rem;
`;

const AppName = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 3rem;
`;

const FormTitle = styled.h2`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 0;
`;

export interface AuthFormProps {
  readonly title: string;
  readonly backlinkText?: string;
  readonly backlinkTo?: string;
  readonly children: React.ReactNode;
}

/**
 * The base layout and card with a React Router form used for all the
 * authentication pages.
 */
export default function AuthForm({
  title,
  backlinkText,
  backlinkTo,
  children,
}: AuthFormProps) {
  return (
    <PageWrapper>
      <CenterWrapper>
        <AuthCard>
          <AuthCardBody>
            <FishLogo src={fishLogo} />

            <AppName>Customer Payments Portal</AppName>

            <FormTitle>{title}</FormTitle>

            {backlinkText && backlinkTo && (
              <LinkButton
                to={backlinkTo}
                color="link"
                className="d-block mx-auto"
              >
                <FaArrowLeft className="me-1" />
                {backlinkText}
              </LinkButton>
            )}

            <FlashMessage className="mt-3" />

            <Form method="POST" className="mt-3">
              {children}
            </Form>
          </AuthCardBody>
        </AuthCard>
      </CenterWrapper>
      <PageFooter />
    </PageWrapper>
  );
}
