import { ActionFunctionArgs } from 'react-router-dom';
import { removeLocation } from '@/api/locations';

export default async function invoicesAction({
  request,
  params,
}: ActionFunctionArgs) {
  const formData = await request.formData();
  const action = formData.get('action') as string;

  if (action === 'delete_location') {
    const locationId = parseInt(params.locationId!);

    return removeLocation(locationId);
  } else {
    throw new Error(`Unknown action: ${action}`);
  }
}
