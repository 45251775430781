import { ActionFunctionArgs } from 'react-router-dom';
import { resendEmailConfirmation } from '@/api/auth';

export default async function confirmEmailAction({
  request,
}: ActionFunctionArgs) {
  const formData = await request.formData();

  const email = formData.get('email') as string;

  return resendEmailConfirmation(email);
}
