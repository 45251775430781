import { Alert } from 'reactstrap';
import AuthForm from '@/features/auth/AuthForm';

export default function RegisterSuccessPage() {
  return (
    <AuthForm title="Sign up">
      <Alert color="success" fade={false}>
        Thanks for registering! Please check your email inbox for a verification
        email.
      </Alert>
    </AuthForm>
  );
}
