import { Alert } from 'reactstrap';
import { FormResponse } from '@/api/client';

export interface FormResponseAlertProps {
  readonly response: FormResponse | null | undefined;
}

export default function FormResponseAlert({
  response,
}: FormResponseAlertProps) {
  if (!response?.message) {
    return null;
  }

  return (
    <Alert color={response.success ? 'success' : 'danger'} fade={false}>
      {response.message}
    </Alert>
  );
}
