import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function getRouteErrorMessage(error: unknown) {
  if (isRouteErrorResponse(error)) {
    return error.statusText;
  } else if (error instanceof Error) {
    return error.message;
  } else {
    return null;
  }
}

export default function ErrorPage() {
  const error = useRouteError();
  const errorMessage = getRouteErrorMessage(error);

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <Wrapper>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      {errorMessage && (
        <p>
          <i>{errorMessage}</i>
        </p>
      )}
    </Wrapper>
  );
}
