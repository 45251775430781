import ButtonRow from '@/components/ButtonRow';
import LinkButton from '@/components/LinkButton';
import TwoColumnCard from '@/components/TwoColumnCard';

/**
 * This card shows the user the benefits of registering an account and has
 * button links to the sign up and sign in pages.
 */
export default function AuthActionsCard() {
  return (
    <TwoColumnCard title="Paying more invoices in the future?">
      <p>
        Register an account with the Customer Payments Portal and get access to
        additional features:
      </p>
      <ul>
        <li>See all your invoices without entering details each time</li>
        <li>Save payment details for easier payments</li>
        <li>Pay multiple invoices at once</li>
        <li>Associate multiple locations with your account</li>
      </ul>

      <ButtonRow>
        <LinkButton to="/register" color="secondary">
          Sign up
        </LinkButton>
        <LinkButton to="/login" color="secondary">
          Log in
        </LinkButton>
      </ButtonRow>
    </TwoColumnCard>
  );
}
