import { Link, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import fishLogo from '@/assets/fish-logo.svg';
import PageFooter from '@/components/PageFooter';
import { breakpointDown } from '@/utils/mediaQueries';

const FishLogo = styled.img`
  height: 2rem;
`;

const Header = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  gap: 1rem;
  text-decoration: none;
  color: var(--bs-dark);

  ${breakpointDown('sm')} {
    flex-direction: column;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export default function GuestPaymentLayout() {
  return (
    <>
      <Header to="/">
        <FishLogo src={fishLogo} alt="Fish Window Cleaning" />
        <h1 className="text-center h3 my-0">Customer Payments Portal</h1>
      </Header>
      <Outlet />
      <PageFooter />
    </>
  );
}
