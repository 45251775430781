import {
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom';
import {
  NavLink as BootstrapNavLink,
  NavLinkProps as BootstrapNavLinkProps,
} from 'reactstrap';

type NavLinkProps = Exclude<BootstrapNavLinkProps, 'tag'> & RouterNavLinkProps;

/**
 * A Bootstrap nav link that uses a React Router link as its tag.
 */
export default function NavLink(props: NavLinkProps) {
  return <BootstrapNavLink tag={RouterNavLink} {...props} />;
}
