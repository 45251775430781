import { ActionFunctionArgs } from 'react-router-dom';
import { register } from '@/api/auth';

export default async function registerAction({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const email = formData.get('email') as string;
  const firstName = formData.get('firstName') as string;
  const lastName = formData.get('lastName') as string;
  const password = formData.get('password') as string;

  return register(email, firstName, lastName, password);
}
