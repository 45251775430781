import { LoaderFunctionArgs } from 'react-router-dom';
import { findInvoice } from '@/api/invoices';

export default async function payInvoiceLoader({
  request,
}: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams;

  const franchiseNumber = searchParams.get('franchiseNumber');
  const workOrderNumber = searchParams.get('workOrderNumber');
  const zipCode = searchParams.get('zipCode');

  if (franchiseNumber && workOrderNumber && zipCode) {
    return findInvoice(
      parseInt(franchiseNumber),
      parseInt(workOrderNumber),
      zipCode,
    );
  } else {
    return {
      invoice: null,
    };
  }
}
