import { useToggle } from '@uidotdev/usehooks';
import { Link } from 'react-router-dom';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap';
import styled from 'styled-components';
import { formatLocationAddressShort, Location } from '@/api/locations';
import { getUserFullName, User } from '@/api/user';
import fishLogo from '@/assets/fish-logo.svg';
import NavLink from './NavLink';

const FishLogo = styled.img`
  height: 2rem;
  margin-left: 0.5rem;
  margin-right: 1rem;
`;

interface AppNavbarProps {
  readonly user: User;
  readonly locations: Location[];
  readonly onLogOutClick: () => void;
}

export default function AppNavbar({
  user,
  locations,
  onLogOutClick,
}: AppNavbarProps) {
  const [isOpen, toggle] = useToggle(false);

  return (
    <Navbar color="white" light expand="md" className="shadow-sm">
      <NavbarBrand>
        <FishLogo src={fishLogo} alt="Fish Window Cleaning" />
        Customer Payments Portal
      </NavbarBrand>
      <NavbarToggler onClick={() => toggle()} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="me-auto" navbar>
          <NavItem className="d-lg-none">
            <NavLink to="/invoices">All Invoices</NavLink>
          </NavItem>
          {locations.map((location) => (
            <NavItem key={location.locationId} className="d-md-none">
              <NavLink to={`/locations/${location.locationId}`}>
                <div>{location.description}</div>
                <div className="text-secondary small">
                  {formatLocationAddressShort(location)}
                </div>
              </NavLink>
            </NavItem>
          ))}
          <UncontrolledDropdown
            inNavbar
            className="d-none d-md-block d-lg-none"
          >
            <DropdownToggle nav caret>
              Locations
            </DropdownToggle>
            <DropdownMenu end>
              {locations.map((location) => (
                <DropdownItem
                  tag={Link}
                  key={location.locationId}
                  to={`/locations/${location.locationId}`}
                >
                  <div>{location.description}</div>
                  <div className="text-secondary small">
                    {formatLocationAddressShort(location)}
                  </div>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

        <UncontrolledDropdown inNavbar>
          <DropdownToggle nav caret>
            {getUserFullName(user)}
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem onClick={onLogOutClick}>Log out</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Collapse>
    </Navbar>
  );
}
