import { useEffect, useState } from 'react';
import { useActionData, useLoaderData, useLocation } from 'react-router-dom';
import { isFormResponse } from '@/api/client';
import { FlashMessage, getCurrentFlashMessage } from '@/utils/flashMessage';

/**
 * Gets a flash message if one is available, whether it was set via an action,
 * loader, or a redirect with a message.
 */
export default function useFlashMessage() {
  const location = useLocation();
  const [flashMessage, setFlashMessage] = useState<FlashMessage | null>(null);
  const loaderData = useLoaderData<unknown>();
  const actionData = useActionData<unknown>();

  // Grab the flash message when the route changes.
  useEffect(() => {
    setFlashMessage(getCurrentFlashMessage());
  }, [location.key]);

  // Prioritize the action data, then the loader data, then a flash message via redirect.
  if (isFormResponse(actionData) && actionData.message) {
    return {
      message: actionData.message,
      color: actionData.success ? 'success' : 'danger',
    };
  } else if (isFormResponse(loaderData) && loaderData.message) {
    return {
      message: loaderData.message,
      color: loaderData.success ? 'success' : 'danger',
    };
  } else {
    return flashMessage;
  }
}
