import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import AppSidebar from '@/components/AppSidebar';
import { useLoggedInLayoutLoaderData } from '../layout.loader';
import { useDashboardLayoutLoaderData } from './layout.loader';

const SidebarAndContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: stretch;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  padding-inline: 3rem;
  padding-block: 2rem;
`;

export default function DashboardLayout() {
  const { locations } = useLoggedInLayoutLoaderData();
  const { outstandingBalance } = useDashboardLayoutLoaderData();

  return (
    <SidebarAndContentWrapper>
      <AppSidebar
        locations={locations}
        outstandingBalance={outstandingBalance}
      />
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
    </SidebarAndContentWrapper>
  );
}
