import { useMap, useSet } from '@uidotdev/usehooks';
import { Fragment } from 'react';
import { FaCreditCard, FaGear, FaMinus, FaPlus } from 'react-icons/fa6';
import { Form, useParams } from 'react-router-dom';
import {
  Badge,
  Card,
  DropdownMenu,
  DropdownToggle,
  Input,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import styled from 'styled-components';
import { getInvoiceStatus, Invoice, InvoiceStatus } from '@/api/invoices';
import { formatLocationAddressShort } from '@/api/locations';
import FlashMessage from '@/components/FlashMessage';
import HeaderSelectionCheckbox from '@/components/HeaderSelectionCheckbox';
import IconButton from '@/components/IconButton';
import InputWithPrefixSuffix from '@/components/InputWithPrefixSuffix';
import LinkButton from '@/components/LinkButton';
import PageHeader from '@/components/PageHeader';
import { parseNumberInput } from '@/utils/numbers';
import { useLoggedInLayoutLoaderData } from '../layout.loader';
import { useDashboardLayoutLoaderData } from './layout.loader';

const TableWrapper = styled(Card)`
  overflow: hidden;
`;

const StyledTable = styled(Table)`
  margin: 0;

  th:first-child,
  td:first-child {
    padding-left: 1rem;
  }

  th:last-child,
  td:last-child {
    padding-right: 1rem;
  }

  thead th {
    background: var(--bs-gray-100);
  }

  tbody tr:last-child td {
    border-bottom: 0;
  }
`;

const TipInput = styled(InputWithPrefixSuffix)`
  display: inline-flex;
  width: 150px;

  &:has(input:disabled) {
    opacity: 0.5;
  }
`;

const AmountText = styled.span`
  display: inline-block;
  min-width: 150px;
`;

const AmountInput = styled(InputWithPrefixSuffix)`
  display: inline-flex;
  width: 150px;
`;

const SummaryTable = styled(Table)`
  max-width: 400px;
  margin-left: auto;

  th,
  td {
    background: transparent;
  }
`;

function formatDate(dateString: string) {
  const date = new Date(dateString);
  return date.toLocaleDateString();
}

function useCurrentLocation() {
  const { locations } = useLoggedInLayoutLoaderData();
  const { invoices: allInvoices } = useDashboardLayoutLoaderData();
  const { locationId: locationIdString } = useParams();

  const locationId =
    locationIdString != null ? parseInt(locationIdString) : null;

  if (locationId == null) {
    return { location: null, invoices: allInvoices };
  } else {
    const location = locations.find((l) => l.locationId === locationId);

    if (location == null) {
      throw new Error(`Location not found: ${locationId}`);
    }

    const invoices = allInvoices.filter(
      (invoice) => invoice.locationId === locationId,
    );

    return { location, invoices };
  }
}

export default function InvoicesPage() {
  const { location, invoices } = useCurrentLocation();

  const selectedInvoiceIds = useSet(
    invoices.map((invoice) => invoice.documentId),
  );

  const additionalServiceAmounts = useMap<number>() as Map<number, string>;
  const invoiceTipAmounts = useMap<number>() as Map<number, string>;

  const selectedInvoices = invoices.filter((invoice) =>
    selectedInvoiceIds.has(invoice.documentId),
  );

  const subtotal = selectedInvoices
    .map((invoice) => invoice.balanceDue - invoice.salesTaxAmount)
    .reduce((sum, amount) => sum + amount, 0);

  const tips = selectedInvoices
    .map((invoice) => invoiceTipAmounts.get(invoice.documentId))
    .reduce((sum, amount) => sum + parseNumberInput(amount), 0);

  const additionalServices = selectedInvoices
    .map((invoice) => additionalServiceAmounts.get(invoice.documentId))
    .reduce((sum, amount) => sum + parseNumberInput(amount), 0);

  const tax = selectedInvoices
    .map(
      (invoice) =>
        invoice.salesTaxAmount +
        invoice.salesTaxRate *
          parseNumberInput(additionalServiceAmounts.get(invoice.documentId)),
    )
    .reduce((sum, amount) => sum + amount, 0);

  const totalBalance = subtotal + tips + additionalServices + tax;

  function toggleInvoiceSelection(invoiceId: number) {
    if (selectedInvoiceIds.has(invoiceId)) {
      selectedInvoiceIds.delete(invoiceId);
      additionalServiceAmounts.delete(invoiceId);
      invoiceTipAmounts.delete(invoiceId);
    } else {
      selectedInvoiceIds.add(invoiceId);
    }
  }

  function selectAllInvoices() {
    invoices.forEach((invoice) => selectedInvoiceIds.add(invoice.documentId));
  }

  return (
    <>
      <FlashMessage className="mb-3" />
      <PageHeader
        title={location?.description ?? 'All Unpaid Invoices'}
        subtitle={
          location != null ? formatLocationAddressShort(location) : null
        }
      >
        <LinkButton to="/locations/add">
          <FaPlus /> Add Location
        </LinkButton>

        {location && (
          <Form method="POST">
            <UncontrolledDropdown>
              <DropdownToggle color="outline-secondary" className="icon-btn">
                <FaGear />
              </DropdownToggle>
              <DropdownMenu end>
                {/* Need to use a normal button here as DropdownItem doesn't seem to submit the form properly. */}
                <button
                  type="submit"
                  name="action"
                  value="delete_location"
                  className="dropdown-item"
                  role="menuitem"
                >
                  Delete location
                </button>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Form>
        )}
      </PageHeader>

      <p className="text-secondary">
        You can select specific invoices you&apos;d like to pay if you
        don&apos;t want to pay all outstanding invoices. Click the plus symbol
        for any invoice row to add additional services or reduce your invoice
        for any removed services.
      </p>

      <TableWrapper>
        <StyledTable>
          <thead>
            <tr>
              <th>
                <HeaderSelectionCheckbox
                  selected={selectedInvoiceIds.size}
                  total={invoices.length}
                  onSelectAll={selectAllInvoices}
                  onClearAll={() => selectedInvoiceIds.clear()}
                />
              </th>
              <th>Work Order #</th>
              <th>Date</th>
              <th>Address</th>
              <th className="text-end">Amount</th>
              <th className="text-end">Status</th>
              <th className="text-end">Add a tip</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <Fragment key={invoice.documentId}>
                <tr>
                  <td className="align-middle">
                    <Input
                      type="checkbox"
                      checked={selectedInvoiceIds.has(invoice.documentId)}
                      onChange={() =>
                        toggleInvoiceSelection(invoice.documentId)
                      }
                    />
                  </td>
                  <td className="align-middle">
                    <a
                      href={invoice.pdfLink}
                      target="_blank"
                      rel="noreferrer"
                      className="text-dark fw-bold"
                    >
                      {invoice.workOrderNumber}
                    </a>
                  </td>
                  <td className="tabular-nums align-middle">
                    {invoice.completedDate ? (
                      formatDate(invoice.completedDate)
                    ) : (
                      <span className="text-secondary">Upcoming</span>
                    )}
                  </td>
                  <td className="align-middle">
                    {formatLocationAddressShort(invoice)}
                  </td>
                  <td className="tabular-nums align-middle text-end">
                    <AmountText>$ {invoice.balanceDue.toFixed(2)}</AmountText>
                  </td>
                  <td className="align-middle text-end">
                    <InvoiceStatusBadge invoice={invoice} />
                  </td>
                  <td className="text-end">
                    <TipInput
                      prefix="$"
                      placeholder="0.00"
                      disabled={!selectedInvoiceIds.has(invoice.documentId)}
                      value={invoiceTipAmounts.get(invoice.documentId) ?? ''}
                      onChange={(event) =>
                        invoiceTipAmounts.set(
                          invoice.documentId,
                          event.target.value,
                        )
                      }
                    />
                  </td>
                  <td className="align-middle text-end">
                    {!additionalServiceAmounts.has(invoice.documentId) && (
                      <IconButton
                        color="outline-secondary"
                        onClick={() =>
                          additionalServiceAmounts.set(invoice.documentId, '')
                        }
                        disabled={!selectedInvoiceIds.has(invoice.documentId)}
                      >
                        <FaPlus />
                      </IconButton>
                    )}
                  </td>
                </tr>
                {additionalServiceAmounts.has(invoice.documentId) && (
                  <tr>
                    <td colSpan={3}></td>
                    <td className="align-middle text-secondary fst-italic">
                      Additional services
                    </td>
                    <td className="text-end">
                      <AmountInput
                        size={1}
                        prefix="$"
                        placeholder="0.00"
                        value={
                          additionalServiceAmounts.get(invoice.documentId) ?? ''
                        }
                        onChange={(event) =>
                          additionalServiceAmounts.set(
                            invoice.documentId,
                            event.target.value,
                          )
                        }
                      />
                    </td>
                    <td colSpan={2}>
                      <InputWithPrefixSuffix
                        size={1}
                        type="text"
                        placeholder="Add a note..."
                      />
                    </td>
                    <td className="align-middle text-end">
                      <IconButton
                        color="outline-danger"
                        onClick={() =>
                          additionalServiceAmounts.delete(invoice.documentId)
                        }
                      >
                        <FaMinus />
                      </IconButton>
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </StyledTable>
      </TableWrapper>

      <SummaryTable className="mt-4">
        <tbody>
          <tr>
            <th>Subtotal</th>
            <td className="text-end tabular-nums">$ {subtotal.toFixed(2)}</td>
          </tr>
          <tr>
            <th>Additional Services</th>
            <td className="text-end tabular-nums">
              $ {additionalServices.toFixed(2)}
            </td>
          </tr>
          <tr>
            <th>Sales Tax</th>
            <td className="text-end tabular-nums">$ {tax.toFixed(2)}</td>
          </tr>
          <tr>
            <th>Tips</th>
            <td className="text-end tabular-nums">$ {tips.toFixed(2)}</td>
          </tr>
          <tr>
            <th>Total</th>
            <td className="text-end tabular-nums fw-bold">
              $ {totalBalance.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </SummaryTable>

      <div className="d-flex justify-content-end mt-5">
        <LinkButton
          to="/pay"
          color="primary"
          size="lg"
          disabled={selectedInvoiceIds.size == 0}
        >
          <FaCreditCard /> Pay Selected Invoices
        </LinkButton>
      </div>
    </>
  );
}

interface InvoiceStatusBadgeProps {
  readonly invoice: Invoice;
}

export function InvoiceStatusBadge({ invoice }: InvoiceStatusBadgeProps) {
  const status = getInvoiceStatus(invoice);

  if (status == InvoiceStatus.Pending) {
    return <Badge color="secondary">Pending</Badge>;
  } else if (status == InvoiceStatus.Due) {
    return <Badge color="warning">Due</Badge>;
  } else if (status == InvoiceStatus.Overdue) {
    return <Badge color="danger">Overdue</Badge>;
  }
}
