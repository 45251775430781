import { useActionData } from 'react-router-dom';
import { FormResponse } from '@/api/client';
import FormInput from '@/components/FormInput';
import LinkButton from '@/components/LinkButton';
import PasswordInput from '@/components/PasswordInput';
import SubmitButton from '@/components/SubmitButton';
import AuthForm from '@/features/auth/AuthForm';

export default function RegisterPage() {
  const actionData = useActionData<FormResponse>();

  return (
    <AuthForm title="Sign up">
      <FormInput name="email" label="Email" required actionData={actionData} />
      <FormInput
        name="firstName"
        label="First Name"
        required
        actionData={actionData}
      />
      <FormInput
        name="lastName"
        label="Last Name"
        required
        actionData={actionData}
      />
      <FormInput
        tag={PasswordInput}
        name="password"
        label="Password"
        required
        actionData={actionData}
      />
      <SubmitButton color="primary" className="w-100 mt-3">
        Register
      </SubmitButton>
      <LinkButton to="/login" color="link" className="d-block mt-3 text-muted">
        Sign in
      </LinkButton>
    </AuthForm>
  );
}
