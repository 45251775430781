import { useActionData, useSearchParams } from 'react-router-dom';
import { FormResponse } from '@/api/client';
import FormInput from '@/components/FormInput';
import SubmitButton from '@/components/SubmitButton';
import AuthForm from '@/features/auth/AuthForm';

export default function ForgotPasswordPage() {
  const actionData = useActionData<FormResponse>();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') ?? '';

  return (
    <AuthForm
      title="Forgot password?"
      backlinkText="Back to login"
      backlinkTo="/login"
    >
      <FormInput
        name="email"
        label="Email"
        required
        actionData={actionData}
        defaultValue={email}
      />
      <SubmitButton color="primary" className="w-100 mt-3">
        Send email
      </SubmitButton>
    </AuthForm>
  );
}
