import * as Sentry from '@sentry/react';
import { redirect } from 'react-router-dom';
import deduplicatePromise from '@/utils/deduplicatePromise';
import { redirectSuccess } from '@/utils/flashMessage';
import client, {
  FieldErrors,
  formError,
  FormResponse,
  formSuccess,
} from './client';
import { components, UserType } from './openapi-schema';

type IdentityResult = components['schemas']['IdentityResult'];

const enum LocalStorageKey {
  AuthToken = 'auth.token',
  RefreshToken = 'auth.refreshToken',
  TokenExpiration = 'auth.tokenExpiration',
}

export function isLoggedIn() {
  return !!localStorage.getItem(LocalStorageKey.RefreshToken);
}

export async function getOrRefreshAuthToken() {
  // Deduplicate calls to this function to prevent multiple calls to refresh the auth token
  return deduplicatePromise('getOrRefreshAuthToken', async () => {
    if (!isLoggedIn()) {
      Sentry.captureMessage('No auth token available', {
        level: 'warning',
      });
      return null;
    }

    let token = localStorage.getItem(LocalStorageKey.AuthToken);

    if (!token || isAuthTokenExpired()) {
      token = await refreshAuthToken();
    }

    return token;
  });
}

function isAuthTokenExpired() {
  const expiration = localStorage.getItem(LocalStorageKey.TokenExpiration);

  if (!expiration) {
    return true;
  }

  const expirationDate = new Date(expiration);
  const now = new Date();

  return expirationDate.getTime() < now.getTime();
}

export interface LoginFormResponse extends FormResponse {
  showResendConfirmEmail?: boolean;
}

export async function logIn(
  email: string,
  password: string,
): Promise<LoginFormResponse> {
  const { data, error } = await client.POST('/authentication/token', {
    body: {
      email,
      password,
    },
  });

  if (!data) {
    if ('succeeded' in error) {
      if (error.isLockedOut) {
        return formError(
          'Sorry, your account has been deactivated. Please contact customer support for assistance.',
        );
      } else if (error.isNotAllowed) {
        return {
          ...formError(
            'Please confirm your email address. Check your email inbox for a verification email.',
          ),
          showResendConfirmEmail: true,
        };
      } else if (error.succeeded === false) {
        return formError('Wrong email or password.');
      }
    }

    return formError('Something went wrong. Please try again.');
  }

  localStorage.setItem(LocalStorageKey.AuthToken, data.authToken);
  localStorage.setItem(LocalStorageKey.RefreshToken, data.refreshToken);
  localStorage.setItem(LocalStorageKey.TokenExpiration, data.utcExpiration!);

  return formSuccess();
}

async function refreshAuthToken(): Promise<string | null> {
  const refreshToken = localStorage.getItem(LocalStorageKey.RefreshToken);

  if (!refreshToken) {
    throw new Error('Refresh token missing');
  }

  const { data, error } = await client.POST('/authentication/retoken', {
    body: {
      RefreshToken: refreshToken,
    },
  });

  if (!data) {
    Sentry.captureMessage('Error refreshing auth token', {
      level: 'error',
      extra: { error },
    });
    return null;
  }

  localStorage.setItem(LocalStorageKey.AuthToken, data.authToken);
  localStorage.setItem(LocalStorageKey.RefreshToken, data.refreshToken);
  localStorage.setItem(LocalStorageKey.TokenExpiration, data.utcExpiration!);

  return data.authToken;
}

export async function register(
  email: string,
  firstName: string,
  lastName: string,
  password: string,
): Promise<FormResponse | Response> {
  const { data, error } = await client.POST('/user/register', {
    body: {
      email,
      firstName,
      lastName,
      password,
      userType: UserType.User,
    },
  });

  if (!data) {
    if ('errors' in error) {
      const fieldErrors = getFieldErrorsFromIdentityResult(error);

      if (Object.keys(fieldErrors).length > 0) {
        return formError(
          'Please check your details and try again.',
          fieldErrors,
        );
      }
    }

    Sentry.captureMessage('Error registering', {
      level: 'error',
      extra: { error },
    });

    return formError('Something went wrong. Please try again.');
  }

  return redirect('/register/success');
}

export function getFieldErrorsFromIdentityResult(
  result: IdentityResult,
): FieldErrors {
  const fieldErrors = {} as FieldErrors;

  for (const error of result.errors) {
    let fieldName: string | null = null;

    if (error.code.includes('Password')) {
      fieldName = 'password';
    } else if (error.code.includes('Email')) {
      fieldName = 'email';
    }

    if (fieldName != null) {
      fieldErrors[fieldName] ??= [];
      fieldErrors[fieldName]?.push(error.description);
    }
  }

  return fieldErrors;
}

export function logOut() {
  localStorage.removeItem(LocalStorageKey.AuthToken);
  localStorage.removeItem(LocalStorageKey.RefreshToken);
  localStorage.removeItem(LocalStorageKey.TokenExpiration);

  Sentry.setUser(null);
}

export async function confirmEmail(email: string, token: string) {
  const { data, error } = await client.POST('/user/confirm', {
    body: {
      email,
      token,
    },
  });

  if (!data) {
    Sentry.captureMessage('Error confirming email', {
      extra: { error },
      level: 'error',
    });
    return formError(
      'Unable to confirm your email address. Please try again or contact customer support for help.',
    );
  }

  return redirectSuccess(
    '/login',
    'Your account has now been confirmed! Please sign in.',
  );
}

export async function resendEmailConfirmation(
  email: string,
): Promise<LoginFormResponse> {
  const { data, error } = await client.POST('/user/resendemailconfirmation', {
    body: {
      email,
    },
  });

  if (!data) {
    Sentry.captureMessage('Error resending email confirmation', {
      extra: { error },
      level: 'error',
    });
    return formError('Something went wrong. Please try again.');
  }

  return formSuccess('Please check your email inbox for a verification email.');
}
