export interface TextOrPlaceholderProps {
  readonly text: string | null | undefined;
}

export default function TextOrPlaceholder({ text }: TextOrPlaceholderProps) {
  if (text) {
    return text;
  } else {
    return <span className="text-body-tertiary">—</span>;
  }
}
