import * as Sentry from '@sentry/react';
import createClient, { Middleware } from 'openapi-fetch';
import { redirect } from 'react-router-dom';
import { getOrRefreshAuthToken, logOut } from './auth';
import type { paths } from './openapi-schema';

export type SchemaPath = keyof paths;

const UNAUTHENTICATED_SCHEMA_PATHS: SchemaPath[] = [
  '/user/register',
  '/user/confirm',
  '/user/resendemailconfirmation',
  '/user/resetpassword',
  '/user/confirmresetpassword',
  '/user/invoice/search',
];

function getSchemaRequiresAuth(schemaPath: string) {
  return (
    !schemaPath.startsWith('/authentication/') &&
    !UNAUTHENTICATED_SCHEMA_PATHS.includes(schemaPath as SchemaPath)
  );
}

const middleware: Middleware = {
  async onRequest({ request, schemaPath }) {
    if (import.meta.env.VITE_API_SECRET) {
      request.headers.set('X-APISecret', import.meta.env.VITE_API_SECRET);
    }

    if (getSchemaRequiresAuth(schemaPath)) {
      const token = await getOrRefreshAuthToken();

      if (!token) {
        console.warn('No token found, redirecting to login');
        logOut();
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw redirect('/login');
      }

      request.headers.set('Authorization', `Bearer ${token}`);
    }

    return request;
  },
  onResponse({ response, schemaPath }) {
    if (response.status === 401 && getSchemaRequiresAuth(schemaPath)) {
      console.warn('Token expired, logging out and redirecting to login');
      Sentry.captureMessage('User unauthorized or token expired', {
        extra: { schemaPath },
        level: 'warning',
      });
      logOut();
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect('/login');
    }

    return response;
  },
};

const client = createClient<paths>({
  baseUrl: import.meta.env.VITE_API_BASE_URL,
});

client.use(middleware);

export default client;

export type FieldErrors = Record<string, string[]>;

export interface FormResponse {
  success: boolean;
  message?: string;
  fieldErrors?: FieldErrors;
}

export function formError(
  message: string,
  fieldErrors?: FieldErrors,
): FormResponse {
  return { success: false, message, fieldErrors };
}

export function formSuccess(message?: string): FormResponse {
  return { success: true, message };
}

export function isFormResponse(value: unknown): value is FormResponse {
  if (!value || typeof value !== 'object') {
    return false;
  }

  const formResponse = value as FormResponse;

  if (typeof formResponse.success !== 'boolean') {
    return false;
  }

  if (
    formResponse.message !== undefined &&
    typeof formResponse.message !== 'string'
  ) {
    return false;
  }

  return true;
}
