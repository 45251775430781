import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import styled from 'styled-components';
import { breakpointDown } from '@/utils/mediaQueries';

const StyledCard = styled(Card)`
  overflow: hidden;

  ${breakpointDown('sm')} {
    border-inline: none;
    border-radius: 0;
    max-width: none;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  background-color: var(--bs-gray-200);
  padding-block: 0.75rem;
  border-radius: 0 !important;
`;

const StyledCardBody = styled(CardBody)<{ $padded: boolean }>`
  padding: ${(props) => (props.$padded ? '1.5rem' : '0')};
`;

export interface TwoColumnCardProps {
  readonly title: string;
  readonly children?: React.ReactNode;
  readonly padded?: boolean;
}

/**
 * This card is used in conjunction with the TwoColumnForm component for the
 * "Add Location" and guest payment flows. On desktop, it looks like a card
 * with rounded corners, but on mobile it extends to the left and right
 * edges of the screen.
 */
export default function TwoColumnCard({
  title,
  children,
  padded = true,
}: TwoColumnCardProps) {
  return (
    <StyledCard>
      <StyledCardHeader tag="h5">{title}</StyledCardHeader>
      <StyledCardBody $padded={padded}>{children}</StyledCardBody>
    </StyledCard>
  );
}
