import * as Sentry from '@sentry/react';
import { redirect, useRouteLoaderData } from 'react-router-dom';
import { isLoggedIn } from '@/api/auth';
import { getLocations, Location } from '@/api/locations';
import { getUser, User } from '@/api/user';

export interface LoggedInLayoutLoaderData {
  user: User;
  locations: Location[];
}

export default async function loggedInLayoutLoader() {
  if (!isLoggedIn()) {
    return redirect('/login');
  }

  const [user, locations] = await Promise.all([getUser(), getLocations()]);

  Sentry.setUser({
    id: user.id,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
  });

  return {
    user,
    locations,
  } as LoggedInLayoutLoaderData;
}

export function useLoggedInLayoutLoaderData() {
  return useRouteLoaderData<LoggedInLayoutLoaderData>('logged-in')!;
}
