import * as Sentry from '@sentry/react';
import client, { formError, FormResponse, formSuccess } from './client';

export type Invoice = Awaited<ReturnType<typeof getInvoices>>[number];
export type InvoiceDetails = Awaited<ReturnType<typeof getInvoice>>;

export enum InvoiceStatus {
  Pending,
  Due,
  Overdue,
}

export interface FindInvoiceResponse extends FormResponse {
  invoice?: InvoiceDetails;
}

export async function findInvoice(
  franchiseId: number,
  workOrderNumber: number,
  zipCode: string,
): Promise<FindInvoiceResponse> {
  const { data, error } = await client.POST('/user/invoice/search', {
    body: {
      franchiseId,
      workOrderNumber,
      zipCode,
    },
  });

  if (error?.status === 404) {
    return formError(
      'Invoice not found. Please check your information and try again.',
    );
  }

  if (!data) {
    console.error('Error looking up invoice', error);
    Sentry.captureMessage('Error looking up invoice', {
      level: 'error',
      extra: { error },
    });
    return formError('Something went wrong. Please try again.');
  }

  return {
    ...formSuccess(),
    invoice: data,
  };
}

export async function getInvoice(franchiseId: number, documentId: number) {
  const { data, error } = await client.GET(
    '/user/invoice/{franchiseid}/{documentid}',
    {
      params: {
        path: {
          franchiseId,
          documentId,
        },
      },
    },
  );

  if (!data) {
    console.error('Error fetching invoice', error);
    Sentry.captureMessage('Error fetching invoice', {
      level: 'error',
      extra: { error },
    });
    throw new Error('Unable to fetch invoice');
  }

  return data;
}

export async function getInvoices() {
  const { data, error } = await client.GET('/user/invoice', {});

  if (!data) {
    console.error('Error fetching invoices', error);
    Sentry.captureMessage('Error fetching invoices', {
      level: 'error',
      extra: { error },
    });
    throw new Error('Unable to fetch invoices');
  }

  return data;
}

export function getInvoiceStatus(invoice: Invoice) {
  if (invoice.dueDate == null) {
    return InvoiceStatus.Pending;
  }

  const dueDate = new Date(invoice.dueDate);

  if (dueDate < new Date()) {
    return InvoiceStatus.Overdue;
  }

  return InvoiceStatus.Due;
}
