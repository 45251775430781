import classNames from 'classnames';
import { useEffect } from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import styled from 'styled-components';

const CardContainer = styled.div`
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  padding: 0.75rem 0.75rem;
  height: 20px;
  box-sizing: content-box;
`;

export interface CreditCardFieldProps {
  readonly paymentCard: usaepay.PaymentCard;
  readonly errorMessage: string | null;
}

/**
 * A credit card field using the USAePay Pay.js v2 library.
 *
 * See https://help.usaepay.info/developer/payjs-v2/
 */
export default function CreditCardField({
  paymentCard,
  errorMessage,
}: CreditCardFieldProps) {
  useEffect(() => {
    const style = {
      base: {
        'font-size': '1rem',
      },
    };

    paymentCard.generateHTML(style);

    paymentCard.addHTML('paymentCardContainer');
  }, [paymentCard]);

  return (
    <FormGroup>
      <Label>Credit Card</Label>

      <CardContainer
        id="paymentCardContainer"
        className={classNames({
          'is-invalid': !!errorMessage,
        })}
      ></CardContainer>

      {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
    </FormGroup>
  );
}
