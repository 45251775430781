import styled from 'styled-components';
import { breakpointDown } from '@/utils/mediaQueries';
import ButtonRow from './ButtonRow';

export interface PageHeaderProps {
  readonly title: string;
  readonly subtitle?: string | null;
  readonly className?: string;
  readonly children?: React.ReactNode;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 1.5rem;
`;

const TitleAndActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${breakpointDown('sm')} {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.5rem;
  }
`;

export default function PageHeader({
  title,
  subtitle,
  className,
  children,
}: PageHeaderProps) {
  return (
    <Wrapper className={className}>
      <TitleAndActions>
        <h2 className="my-0">{title}</h2>

        {children && <ButtonRow>{children}</ButtonRow>}
      </TitleAndActions>

      {subtitle && <h4 className="fw-normal">{subtitle}</h4>}
    </Wrapper>
  );
}
