import SubmitButton from '@/components/SubmitButton';
import AuthForm from '@/features/auth/AuthForm';

export default function ConfirmUserPage() {
  return (
    <AuthForm title="Confirm Email">
      <SubmitButton color="warning" className="w-100 mt-3">
        Resend Verification Email
      </SubmitButton>
    </AuthForm>
  );
}
