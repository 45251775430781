import { redirect } from 'react-router-dom';
import { isLoggedIn } from '@/api/auth';

/**
 * We only want to show the guest pages if the user is not logged in, so we
 * redirect to the invoices page if the user is logged in.
 */
export default function guestLayoutLoader() {
  if (isLoggedIn()) {
    return redirect('/invoices');
  }

  return {};
}
