const inflightPromises = new Map<string, Promise<unknown>>();

/**
 * Deduplicate promises by ID so that only one promise is in-flight at a time.
 */
export default function deduplicatePromise<T>(
  id: string,
  func: () => Promise<T>,
): Promise<T> {
  let promise = inflightPromises.get(id) as Promise<T> | undefined;

  if (!promise) {
    promise = func();
    inflightPromises.set(id, promise);
  }

  return promise.then((value) => {
    inflightPromises.delete(id);
    return value;
  });
}
