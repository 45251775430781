import { Button } from 'reactstrap';
import styled from 'styled-components';

export default styled(Button)`
  padding: var(--bs-btn-padding-y);

  svg {
    margin-right: 0 !important;
  }
`;
