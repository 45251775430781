import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import { logOut } from '@/api/auth';
import AppNavbar from '@/components/AppNavbar';
import PageFooter from '@/components/PageFooter';
import { LoggedInLayoutLoaderData } from './layout.loader';

export default function LoggedInLayout() {
  const { user, locations } = useLoaderData<LoggedInLayoutLoaderData>();
  const navigate = useNavigate();

  async function logOutAndRedirect() {
    logOut();
    await navigate('/');
  }

  return (
    <>
      <AppNavbar
        user={user}
        locations={locations}
        onLogOutClick={() => void logOutAndRedirect()}
      />

      <Outlet />

      <PageFooter />
    </>
  );
}
