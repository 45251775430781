import { Alert } from 'reactstrap';
import useFlashMessage from '@/hooks/useFlashMessage';

export interface FlashMessageProps {
  readonly className?: string;
}

/**
 * Shows a flash message if one is available, whether it was set via an action,
 * loader, or a redirect with a message.
 */
export default function FlashMessage({ className }: FlashMessageProps) {
  const flashMessage = useFlashMessage();

  if (!flashMessage) {
    return null;
  }

  return (
    <Alert color={flashMessage.color} fade={false} className={className}>
      {flashMessage.message}
    </Alert>
  );
}
