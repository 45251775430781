import { ActionFunctionArgs } from 'react-router-dom';
import { logIn, resendEmailConfirmation } from '@/api/auth';

export default async function loginAction({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const action = formData.get('action') as string;

  if (action === 'login') {
    const email = formData.get('email') as string;
    const password = formData.get('password') as string;

    return logIn(email, password);
  } else if (action === 'resend_confirm_email') {
    const email = formData.get('email') as string;

    return resendEmailConfirmation(email);
  } else {
    throw new Error(`Invalid login action: ${action}`);
  }
}
