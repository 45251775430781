import { FaRegCreditCard, FaUser } from 'react-icons/fa6';
import { Card, CardText, CardTitle } from 'reactstrap';
import styled from 'styled-components';
import fishLogo from '@/assets/fish-logo.svg';
import ButtonRow from '@/components/ButtonRow';
import LinkButton from '@/components/LinkButton';
import PageFooter from '@/components/PageFooter';
import { breakpointUp } from '@/utils/mediaQueries';

const PageWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: var(--bs-gray-200);
`;

const CardContainer = styled.div`
  display: grid;
  gap: 2rem;
  max-width: 800px;
  margin-top: 4rem;
  margin-inline: auto;

  ${breakpointUp('md')} {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  padding: 2rem;
`;

const FishLogo = styled.img`
  height: 6rem;
  display: block;
  margin-inline: auto;
`;

const CardImageWrapper = styled.div`
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  margin-bottom: 2rem;
  border-radius: 999px;
  background-color: var(--bs-secondary-bg-subtle);
`;

/**
 * We redirect to the /invoices page for logged in users, so this root index
 * page will only be shown to guests.
 */
export default function IndexPage() {
  return (
    <PageWrapper>
      <div className="p-5 flex-grow-1">
        <FishLogo src={fishLogo} alt="Fish Window Cleaning" />
        <h1 className="text-center mt-4">Customer Payments Portal</h1>

        <CardContainer>
          <StyledCard body className="shadow-sm">
            <CardImageWrapper>
              <FaRegCreditCard size="4rem" />
            </CardImageWrapper>
            <CardTitle tag={'h4'}>Quick Pay</CardTitle>
            <CardText className="flex-grow-1">
              Just need to pay your invoice? Have your franchise number, work
              order number, and zip code from an invoice handy. No login
              necessary.
            </CardText>
            <LinkButton
              to="/guest-payment"
              size="lg"
              color="primary"
              className="mt-3"
            >
              Quick Pay
            </LinkButton>
          </StyledCard>

          <StyledCard body className="shadow-sm">
            <CardImageWrapper>
              <FaUser size="3rem" />
            </CardImageWrapper>
            <CardTitle tag={'h4'}>Sign In or Register</CardTitle>
            <CardText className="flex-grow-1">
              Create an account to view and pay invoices and manage single or
              multiple locations.
            </CardText>
            <ButtonRow className="mt-3">
              <LinkButton to="/register" size="lg" color="secondary">
                Sign Up
              </LinkButton>
              <LinkButton to="/login" size="lg" color="secondary">
                Log In
              </LinkButton>
            </ButtonRow>
          </StyledCard>
        </CardContainer>
      </div>
      <PageFooter />
    </PageWrapper>
  );
}
