import styled from 'styled-components';

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function SuccessfulGuestPaymentPage() {
  return (
    <Wrapper>
      <h1>Payment success!</h1>
      <p>Thanks for paying your invoice!</p>
    </Wrapper>
  );
}
