import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import ButtonRow from '@/components/ButtonRow';
import CreditCardField from '@/components/CreditCardField';
import FormInput from '@/components/FormInput';
import SubmitButton from '@/components/SubmitButton';
import TwoColumnCard from '@/components/TwoColumnCard';

export interface BillingDetailsCardProps {
  readonly paymentCard: usaepay.PaymentCard;
  readonly cardError: string | null;
  readonly isBusy: boolean;
}

export default function BillingDetailsCard({
  paymentCard,
  cardError,
  isBusy,
}: BillingDetailsCardProps) {
  return (
    <TwoColumnCard title="Enter Billing Details">
      <Row>
        <Col md={6}>
          <FormInput name="firstName" label="First Name" required />
        </Col>

        <Col md={6}>
          <FormInput name="lastName" label="Last Name" required />
        </Col>

        <Col xs={12}>
          <FormInput name="street" label="Street Address" required />
        </Col>

        <Col xs={12}>
          <FormInput name="street2" label="Apt/Suite" />
        </Col>

        <Col md={6}>
          <FormInput name="city" label="City" required />
        </Col>

        <Col md={3}>
          <FormInput name="state" label="State" required />
        </Col>

        <Col md={3}>
          <FormInput name="postalCode" label="Zip Code" required />
        </Col>

        <Col xs={12}>
          <FormInput name="phone" label="Phone Number" type="tel" />
        </Col>

        <Col xs={12}>
          <FormInput name="email" label="Email Address" type="email" />
        </Col>

        <Col xs={12}>
          <CreditCardField paymentCard={paymentCard} errorMessage={cardError} />
        </Col>

        <Col xs={12}>
          <FormGroup check className="mt-2 mb-4">
            <Input
              type="checkbox"
              name="sendReceipt"
              id="sendReceipt"
              defaultChecked
            />
            <Label for="sendReceipt" check>
              Email me a receipt
            </Label>
          </FormGroup>
        </Col>

        <ButtonRow className="mt-3">
          <SubmitButton busy={isBusy} color="success">
            Submit Payment
          </SubmitButton>
        </ButtonRow>
      </Row>
    </TwoColumnCard>
  );
}
