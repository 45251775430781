import * as Sentry from '@sentry/react';
import { redirect } from 'react-router-dom';
import { redirectSuccess } from '@/utils/flashMessage';
import client, { formError } from './client';
import { Invoice } from './invoices';

export type Location = Awaited<ReturnType<typeof getLocations>>[number];

export async function getLocations() {
  const { data, error } = await client.GET('/user/location');

  if (!data) {
    console.error('Error fetching locations', error);
    Sentry.captureMessage('Error fetching locations', {
      level: 'error',
      extra: { error },
    });
    throw new Error('Unable to fetch locations');
  }

  return data;
}

export async function addLocation(
  franchiseId: number,
  workOrderNumber: number,
  zipCode: string,
) {
  const { error } = await client.POST('/user/location', {
    body: {
      franchiseId,
      workOrderNumber,
      zipCode,
    },
  });

  if (error) {
    console.error('Error associating location', error);
    Sentry.captureMessage('Error associating location', {
      level: 'error',
      extra: { error },
    });
    return formError('Unable to associate location');
  }

  return redirect('/invoices');
}

export async function removeLocation(locationId: number) {
  const { error, response } = await client.DELETE(
    '/user/location/{locationid}',
    {
      params: {
        path: { locationid: locationId },
      },
    },
  );

  if (!response.ok) {
    console.error('Error removing location', error);
    Sentry.captureMessage('Error removing location', {
      level: 'error',
      extra: {
        error,
        status: response.status,
        statusText: response.statusText,
      },
    });
    return formError('Unable to remove location');
  }

  return redirectSuccess('/invoices', 'Location removed');
}

export function formatLocationAddressShort(location: Location | Invoice) {
  return `${location.addressLine1}, ${location.city}, ${location.state}`;
}
