import { ActionFunctionArgs } from 'react-router-dom';
import { forgotPassword } from '@/api/user';

export default async function forgotPasswordAction({
  request,
}: ActionFunctionArgs) {
  const formData = await request.formData();

  const email = formData.get('email') as string;

  return forgotPassword(email);
}
