import { Outlet } from 'react-router-dom';
import { Alert } from 'reactstrap';

/**
 * The base layout just adds a banner at the top of every page for
 * local/dev/staging environments.
 */
export default function BaseLayout() {
  const isNonProd = import.meta.env.MODE !== 'production';
  const modeName = capitalizeFirstLetter(import.meta.env.MODE);

  return (
    <>
      {isNonProd && (
        <Alert color="warning" fade={false} className="text-center py-2 mb-0">
          {modeName} environment
        </Alert>
      )}
      <Outlet />
    </>
  );
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
