import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'stagingapi.fish3sqls.com',
    'api.fish3sqls.com',
  ],
  // Session Replay
  replaysSessionSampleRate: parseFloat(
    import.meta.env.VITE_REPLAYS_SESSION_SAMPLE_RATE,
  ),
  replaysOnErrorSampleRate: 1.0,
  sendDefaultPii: true,
});
