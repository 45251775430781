import { Input, InputGroup, InputGroupText, InputProps } from 'reactstrap';

export interface InputWithPrefixSuffixProps extends InputProps {
  readonly prefix?: string;
  readonly suffix?: string;
}

/**
 * An Input component that can display an optional prefix and/or suffix.
 */
export default function InputWithPrefixSuffix({
  className,
  prefix,
  suffix,
  ...props
}: InputWithPrefixSuffixProps) {
  if (prefix || suffix) {
    return (
      <InputGroup className={className}>
        {prefix && <InputGroupText>{prefix}</InputGroupText>}
        <Input {...props} />
        {suffix && <InputGroupText>{suffix}</InputGroupText>}
      </InputGroup>
    );
  } else {
    return <Input {...props} />;
  }
}
