import { Table } from 'reactstrap';
import { InvoiceDetails } from '@/api/invoices';
import TextOrPlaceholder from '@/components/TextOrPlaceholder';
import TwoColumnCard from '@/components/TwoColumnCard';
import { formatDate } from '@/utils/dates';

export interface PaymentHistoryCardProps {
  readonly invoice: InvoiceDetails;
}

export default function PaymentHistoryCard({
  invoice,
}: PaymentHistoryCardProps) {
  return (
    <TwoColumnCard title="Payment History">
      {invoice.balanceDue > 0 ? (
        <p>You&apos;ve already made some payments on this invoice:</p>
      ) : (
        <p>You&apos;ve already paid this invoice in full:</p>
      )}
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th className="text-end">Amount</th>
          </tr>
        </thead>
        <tbody>
          {invoice.payments.map((payment) => (
            <tr
              key={payment.paymentDetailId}
              data-payment-detail-id={payment.paymentDetailId}
            >
              <td>{formatDate(payment.paymentDate)}</td>
              <td>
                <TextOrPlaceholder text={payment.type.description} />
              </td>
              <td className="text-end tabular-nums">
                ${payment.amount.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="table-group-divider">
          <tr>
            <th colSpan={2}>Total Payments</th>
            <th className="text-end tabular-nums">
              $
              {invoice.payments
                .reduce((sum, payment) => sum + payment.amount, 0)
                .toFixed(2)}
            </th>
          </tr>
          <tr>
            <th colSpan={2}>Total Invoice Amount</th>
            <th className="text-end tabular-nums">
              ${invoice.amount.toFixed(2)}
            </th>
          </tr>
          <tr>
            <th colSpan={2}>Balance Due</th>
            <th className="text-end tabular-nums">
              ${invoice.balanceDue.toFixed(2)}
            </th>
          </tr>
        </tfoot>
      </Table>
    </TwoColumnCard>
  );
}
