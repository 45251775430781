import styled from 'styled-components';

/**
 * A div with the correct padding accounting for the label above inputs and the
 * padding below the inputs so the the contents of this separate are nicely
 * centered between the actual inputs.
 */
const FormInputSeparator = styled.div`
  padding-top: 32px;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  margin-inline: 0.5rem;
`;

export default FormInputSeparator;
