import { useToggle } from '@uidotdev/usehooks';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';
import { Button, Input, InputGroup, InputProps } from 'reactstrap';

/**
 * A password input that allows the user to toggle the visibility of the password.
 * Used in the login and register forms.
 */
export default function PasswordInput(props: InputProps) {
  const [showPassword, toggleShowPassword] = useToggle(false);

  return (
    <InputGroup>
      <Input {...props} type={showPassword ? 'text' : 'password'} />
      <Button onClick={() => toggleShowPassword()} outline>
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </Button>
    </InputGroup>
  );
}
