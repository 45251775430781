type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

//See https://getbootstrap.com/docs/5.0/layout/breakpoints/#min-width
const MIN_WIDTH_BREAKPOINTS = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};

//See https://getbootstrap.com/docs/5.0/layout/breakpoints/#max-width
const MAX_WIDTH_BREAKPOINTS = {
  sm: '575.98px',
  md: '767.98px',
  lg: '991.98px',
  xl: '1199.98px',
  xxl: '1399.98px',
};

/**
 * A media query helper for styled-components that targets screen sizes greater
 * than or equal to the given Bootstrap breakpoint.
 */
export function breakpointUp(size: Breakpoint) {
  return `@media (min-width: ${MIN_WIDTH_BREAKPOINTS[size]})`;
}

/**
 * A media query helper for styled-components that targets screen sizes less
 * than the given Bootstrap breakpoint.
 */
export function breakpointDown(size: Breakpoint) {
  return `@media (max-width: ${MAX_WIDTH_BREAKPOINTS[size]})`;
}
