import { ActionFunctionArgs } from 'react-router-dom';
import { formError } from '@/api/client';
import { addLocation } from '@/api/locations';

export default async function addLocationAction({
  request,
}: ActionFunctionArgs) {
  const formData = await request.formData();
  const franchiseId = parseFloat(formData.get('franchiseNumber') as string);
  const workOrderNumber = parseFloat(formData.get('workOrderNumber') as string);
  const zipCode = formData.get('zipCode') as string;

  if (!franchiseId) {
    return formError('Invalid franchise number');
  }

  if (!workOrderNumber) {
    return formError('Invalid work order number');
  }

  return addLocation(franchiseId, workOrderNumber, zipCode);
}
