import { useRouteLoaderData } from 'react-router-dom';
import { getInvoices, Invoice } from '@/api/invoices';

export interface DashboardLayoutLoaderData {
  invoices: Invoice[];
  outstandingBalance: number;
}

export default async function dashboardLayoutLoader() {
  const invoices = await getInvoices();
  const outstandingBalance = invoices.reduce(
    (total, invoice) => total + invoice.balanceDue,
    0,
  );

  return {
    invoices,
    outstandingBalance,
  } satisfies DashboardLayoutLoaderData;
}

export function useDashboardLayoutLoaderData() {
  return useRouteLoaderData<DashboardLayoutLoaderData>('dashboard')!;
}
