/**
 * Parses a string as a float but returns 0 instead of NaN.
 */
export function parseNumberInput(value: string | undefined | null): number {
  if (!value) {
    return 0;
  }

  const number = parseFloat(value);

  if (isNaN(number)) {
    return 0;
  }

  return number;
}
