import { Link, LinkProps } from 'react-router-dom';
import { Button, ButtonProps } from 'reactstrap';

type LinkButtonProps = Exclude<ButtonProps, 'tag'> & LinkProps;

/**
 * A Bootstrap button that uses a React Router link as its tag.
 */
export default function LinkButton(props: LinkButtonProps) {
  return <Button tag={Link} {...props} />;
}
