import { useState } from 'react';
import { Button, ButtonGroup, Col, Row, Table } from 'reactstrap';
import { InvoiceDetails } from '@/api/invoices';
import FormInput from '@/components/FormInput';
import TwoColumnCard from '@/components/TwoColumnCard';
import { parseNumberInput } from '@/utils/numbers';

const TIP_PERCENTS = [0.15, 0.2];

export interface ConfirmAmountCardProps {
  readonly invoice: InvoiceDetails;
}

export default function ConfirmAmountCard({ invoice }: ConfirmAmountCardProps) {
  const [additionalServicesString, setAdditionalServicesString] = useState('');
  const [tipString, setTipString] = useState('');

  const additionalServices = parseNumberInput(additionalServicesString);
  const tip = parseNumberInput(tipString);

  const subtotal = invoice.balanceDue - invoice.salesTaxAmount;

  const subtotalWithAdditionalServices = subtotal + additionalServices;

  const salesTax =
    invoice.salesTaxAmount + additionalServices * invoice.salesTaxRate;

  const total = subtotal + additionalServices + salesTax + tip;

  function onAdditionalServicesChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    setAdditionalServicesString(event.target.value);
  }

  function onTipChange(event: React.ChangeEvent<HTMLInputElement>) {
    setTipString(event.target.value);
  }

  return (
    <TwoColumnCard title="Confirm Amount">
      <p>
        Did you change the work done at time of service? You can add charges for
        additional services or enter a negative amount to reduce your invoice
        for any removed services. Please add a note if a negative amount is
        entered.
      </p>
      <Row>
        <Col md={6}>
          <FormInput
            name="additionalServices"
            label="Additional Services"
            prefix="$"
            type="number"
            placeholder="0.00"
            step={0.01}
            min={0}
            value={additionalServicesString}
            onChange={onAdditionalServicesChange}
          />
        </Col>
        <Col md={6}>
          <FormInput
            name="additionalServicesNote"
            label="Note"
            required={additionalServices < 0}
          />
        </Col>
        <Col md={6}>
          <FormInput
            name="tip"
            label="Tip"
            prefix="$"
            suffix={formatTipPercent(subtotalWithAdditionalServices, tip)}
            type="number"
            placeholder="0.00"
            step={0.01}
            min={0}
            value={tipString}
            onChange={onTipChange}
          />

          <ButtonGroup className="d-block">
            {TIP_PERCENTS.map((percent) => (
              <ToggleTipButton
                key={percent}
                percent={percent}
                amount={subtotalWithAdditionalServices}
                tip={tip}
                setTipString={setTipString}
              />
            ))}
          </ButtonGroup>
        </Col>
      </Row>

      <Table className="mt-4">
        <tbody>
          <tr>
            <th>Subtotal</th>
            <td className="text-end tabular-nums">$ {subtotal.toFixed(2)}</td>
          </tr>
          <tr>
            <th>Additional Services</th>
            <td className="text-end tabular-nums">
              $ {additionalServices.toFixed(2)}
            </td>
          </tr>
          {invoice.isTaxed && (
            <tr>
              <th>Sales Tax ({formatSalesTaxRate(invoice.salesTaxRate)})</th>
              <td className="text-end tabular-nums">$ {salesTax.toFixed(2)}</td>
            </tr>
          )}
          <tr>
            <th>Tip</th>
            <td className="text-end tabular-nums">$ {tip.toFixed(2)}</td>
          </tr>
        </tbody>
        <tfoot className="table-group-divider">
          <tr>
            <th>Total</th>
            <th className="text-end tabular-nums">$ {total.toFixed(2)}</th>
          </tr>
        </tfoot>
      </Table>
    </TwoColumnCard>
  );
}

interface ToggleTipButtonProps {
  readonly percent: number;
  readonly amount: number;
  readonly tip: number;
  readonly setTipString: (value: string) => void;
}

function ToggleTipButton({
  percent,
  amount,
  tip,
  setTipString,
}: ToggleTipButtonProps) {
  const isActive = amount > 0 && Math.abs(tip - amount * percent) < 0.01;

  function onClick() {
    if (isActive) {
      setTipString('');
    } else {
      setTipString((amount * percent).toFixed(2));
    }
  }

  return (
    <Button
      key={percent}
      color="outline-secondary"
      active={isActive}
      onClick={onClick}
    >
      {(percent * 100).toFixed(0)}%
    </Button>
  );
}

function formatTipPercent(amount: number, tip: number) {
  if (amount > 0 && tip > 0) {
    return ((tip / amount) * 100).toFixed(0) + '%';
  } else {
    return '';
  }
}

function formatSalesTaxRate(rate: number) {
  return (rate * 100).toFixed(4).replace(/\.?0+$/, '') + '%';
}
