import { ActionFunctionArgs } from 'react-router-dom';
import { formError } from '@/api/client';
import { resetPassword } from '@/api/user';

export default async function resetPasswordAction({
  request,
}: ActionFunctionArgs) {
  const searchParams = new URL(request.url).searchParams;
  const formData = await request.formData();
  const email = searchParams.get('e');
  const token = searchParams.get('t');
  const password = formData.get('password') as string;

  if (!email || !token) {
    return formError('Invalid reset password link');
  }

  return resetPassword(email, token, password);
}
