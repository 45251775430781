import { FormMethod, Link, useSearchParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FormResponse } from '@/api/client';
import annotatedSampleInvoice from '@/assets/annotated_sample_invoice.png';
import ButtonRow from '@/components/ButtonRow';
import FormInput from '@/components/FormInput';
import FormInputSeparator from '@/components/FormInputSeparator';
import FormResponseAlert from '@/components/FormResponseAlert';
import SubmitButton from '@/components/SubmitButton';
import TwoColumnCard from '@/components/TwoColumnCard';
import TwoColumnForm from '@/components/TwoColumnForm';
import AuthActionsCard from '@/features/payment/AuthActionsCard';

export interface FindInvoiceFormProps {
  readonly title: string;
  readonly subtitle: string;
  readonly method?: FormMethod;
  readonly actionLabel: string;
  readonly cancelRoute?: string;
  readonly showAuthActions?: boolean;
  readonly actionData?: FormResponse;
}

/**
 * This component serves as the basis for both the "Add Location" page and the first
 * step of the "Pay Invoice" page where the user needs to look up their invoice.
 */
export default function FindInvoiceForm({
  title,
  subtitle,
  method,
  actionLabel,
  cancelRoute,
  showAuthActions,
  actionData,
}: FindInvoiceFormProps) {
  return (
    <TwoColumnForm
      title={title}
      subtitle={subtitle}
      method={method}
      leftColumn={
        <EnterInvoiceDetailsCard
          actionLabel={actionLabel}
          cancelRoute={cancelRoute}
          actionData={actionData}
        />
      }
      rightColumn={
        <>
          <AnnotatedInvoiceCard />
          {showAuthActions && <AuthActionsCard />}
        </>
      }
    />
  );
}

interface EnterInvoiceDetailsCardProps {
  readonly actionLabel: string;
  readonly cancelRoute?: string;
  readonly actionData?: FormResponse;
}

function EnterInvoiceDetailsCard({
  actionLabel,
  cancelRoute,
  actionData,
}: EnterInvoiceDetailsCardProps) {
  const [searchParams] = useSearchParams();

  return (
    <TwoColumnCard title="Enter Invoice Details">
      <FormResponseAlert response={actionData} />

      <h5 className="mb-3">
        Please enter your invoice number as shown on your invoice:
      </h5>
      <div className="d-flex">
        <FormInput
          name="franchiseNumber"
          label="Franchise #"
          required
          defaultValue={searchParams.get('franchiseNumber') ?? ''}
        />
        <FormInputSeparator>-</FormInputSeparator>
        <FormInput
          name="workOrderNumber"
          label="Work Order #"
          required
          defaultValue={searchParams.get('workOrderNumber') ?? ''}
        />
      </div>

      <div className="d-flex">
        <FormInput
          name="zipCode"
          label="Zip Code"
          required
          defaultValue={searchParams.get('zipCode') ?? ''}
        />
      </div>

      <ButtonRow className="mt-4">
        {cancelRoute && (
          <Button tag={Link} to={cancelRoute} size="lg" color="secondary">
            Cancel
          </Button>
        )}
        <SubmitButton size="lg" color="primary" type="submit">
          {actionLabel}
        </SubmitButton>
      </ButtonRow>
    </TwoColumnCard>
  );
}

function AnnotatedInvoiceCard() {
  return (
    <TwoColumnCard title="Where do I find these details?" padded={false}>
      <img
        src={annotatedSampleInvoice}
        alt="Annotated sample invoice"
        className="img-fluid"
      />
    </TwoColumnCard>
  );
}
