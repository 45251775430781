import { useState } from 'react';
import { Link, useActionData } from 'react-router-dom';
import styled from 'styled-components';
import { LoginFormResponse } from '@/api/auth';
import FormInput from '@/components/FormInput';
import LinkButton from '@/components/LinkButton';
import PasswordInput from '@/components/PasswordInput';
import SubmitButton from '@/components/SubmitButton';
import AuthForm from '@/features/auth/AuthForm';

const ForgotPasswordLink = styled(Link)`
  color: var(--bs-secondary-color);
  text-decoration: none;
  float: right;

  &:hover {
    text-decoration: underline;
  }
`;

export default function LoginPage() {
  const actionData = useActionData<LoginFormResponse>();

  const [email, setEmail] = useState('');

  function onEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  return (
    <AuthForm title="Sign in">
      {actionData?.showResendConfirmEmail && (
        <SubmitButton
          color="warning"
          className="w-100 mb-3"
          action="resend_confirm_email"
        >
          Resend Verification Email
        </SubmitButton>
      )}

      <FormInput
        name="email"
        label="Email"
        required
        actionData={actionData}
        value={email}
        onChange={onEmailChange}
      />
      <FormInput
        tag={PasswordInput}
        name="password"
        label="Password"
        required
        actionData={actionData}
      >
        <ForgotPasswordLink to={`/forgot-password?email=${email}`}>
          Forgot password?
        </ForgotPasswordLink>
      </FormInput>
      <SubmitButton color="primary" className="w-100 mt-3" action="login">
        Sign In
      </SubmitButton>
      <LinkButton
        to="/register"
        color="link"
        className="d-block mt-3 text-muted"
      >
        Register
      </LinkButton>
    </AuthForm>
  );
}
