import { useEffect, useState } from 'react';
import usaepayClient from '@/api/usaepayClient';

/**
 * See https://help.usaepay.info/developer/payjs-v2/
 */
export default function usePaymentCard() {
  const [paymentCard] = useState(() => usaepayClient.createPaymentCardEntry());
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function getPaymentKey() {
    try {
      const result = await usaepayClient.getPaymentKey(paymentCard);

      if ('error' in result) {
        setErrorMessage(result.error.message);
      } else {
        setErrorMessage(null);
        return result;
      }
    } catch (error) {
      if (typeof error === 'string') {
        setErrorMessage(error);
      } else {
        throw error;
      }
    }
  }

  useEffect(() => {
    function handleError(error: usaepay.PaymentCardError) {
      setErrorMessage(error.message);
    }

    // There is not an equivalent removeEventListener in usaepay.PaymentCardEntry
    paymentCard.addEventListener('error', handleError);
  }, [paymentCard]);

  return { paymentCard, getPaymentKey, errorMessage };
}
