import { redirect } from 'react-router-dom';

export type FlashMessageColor = 'success' | 'danger' | 'warning' | 'info';

export interface FlashMessage {
  readonly message: string;
  readonly color: FlashMessageColor;
}

// The pending flash message becomes current any time the route changes. Thus,
// if the user clicks on a link or a redirect is triggered without a new
// pending message being set, the previous message will be cleared.
let pendingFlashMessage: FlashMessage | null = null;
let currentFlashMessage: FlashMessage | null = null;

export function getCurrentFlashMessage() {
  return currentFlashMessage;
}

/**
 * Call this when the route has changed (via router.subscribe).
 */
export function makePendingFlashMessageCurrent() {
  currentFlashMessage = pendingFlashMessage;
  pendingFlashMessage = null;
}

export function redirectWithMessage(
  url: string,
  message: string,
  color: FlashMessageColor,
) {
  pendingFlashMessage = { message, color };

  return redirect(url);
}

export function redirectSuccess(url: string, message: string) {
  return redirectWithMessage(url, message, 'success');
}
